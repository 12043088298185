/**
 * This JavaScript code defines a module, NewHomeSource.GoogleOneTapHandler, responsible for handling Google One Tap
 * authentication on NewHomeSource.
 * The module provides functions for loading the Google One Tap API asynchronously, displaying the One Tap login modal,
 * and parsing the JSON Web Token (JWT) obtained from the authentication process.
 * It utilizes promises for handling the asynchronous operations, ensures single-loading of the Google One Tap API,
 * and includes callbacks for various events such as library loading and authentication success.
 * The module is designed to seamlessly integrate Google One Tap authentication into NewHomeSource, promoting a
 * simplified and secure user authentication experience.
 */
(function googleOneTapHandler(global) {
  'use strict';

  global.NewHomeSource = global.NewHomeSource || {};

  const previousGoogleOneTapHandler = global.NewHomeSource.GoogleOneTapHandler;
  let GoogleOneTapHandler = {};

  const googleOneTapInstance = {
    isGoogleOneTapLoaded: false,
    isGoogleOneTapLoading: false,
    clientId: null,
    onAuthenticated: null,
  };

  // Interval to sample waiting for the load event; this number is arbitrary.
  const googleOneTapLoadSampleRate = 100;

  /**
   * If the Google One Tap API fails to load, set the loading and loaded flags to false and reject the promise.
   * @param reject - A function that rejects the promise.
   */
  const onGoogleOneTapError = (reject) => {
    googleOneTapInstance.isGoogleOneTapLoading = false;
    googleOneTapInstance.isGoogleOneTapLoaded = false;
    reject(new Error('Unable to load Google One Tap API.'));
  };

  /**
   * When the Google One Tap API is loaded, set the isGoogleOneTapLoaded property of the googleOneTapInstance object to
   * true and resolve the promise.
   * @param resolve - A function that is called when the promise is resolved.
   */
  const onGoogleOneTapLoaded = (resolve) => {
    window.google.accounts.id.initialize({
      client_id: googleOneTapInstance.clientId,
      callback: googleOneTapInstance.onAuthenticated,
      cancel_on_tap_outside: false,
      // Due to Intelligent Tracking Prevention (ITP), the normal One Tap UX doesn't work on
      // Chrome on iOS, Safari, or Firefox. A different UX is provided instead on these browsers.
      itp_support: true,
      use_fedcm_for_prompt: true,
    });
    googleOneTapInstance.isGoogleOneTapLoaded = true;
    resolve();
  };

  /**
   * Create a script element, set its source to the Google One Tap source,
   * set its onerror property to a function that rejects the promise,
   * set its onload property to a function that resolves the promise.
   * @param resolve - The function to call when the script has loaded.
   * @param reject - A function that is called when the promise is rejected.
   */
  const appendGoogleOneTapScript = (resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onerror = onGoogleOneTapError.bind(this, reject);
    script.onload = onGoogleOneTapLoaded.bind(this, resolve);
    document.head.appendChild(script);
  };

  /**
   * If the Google One Tap is loaded, resolve the promise.
   * If not, wait the amount of time from googleOneTapLoadSampleRate and try again.
   * @param resolve - The resolve function of the promise.
   */
  const isGoogleOneTapLoaded = (resolve) => {
    if (googleOneTapInstance.isGoogleOneTapLoaded) {
      resolve();
    } else {
      setTimeout(isGoogleOneTapLoaded.bind(null, resolve), googleOneTapLoadSampleRate);
    }
  };

  /**
   * Wait the amount of time from googleOneTapLoadSampleRate for Google One Tap to load, and then resolve the promise.
   * @returns A promise that will resolve when the Google One Tap API is loaded.
   */
  const waitForGoogleOneTap = () => new Promise((resolve) => {
    setTimeout(isGoogleOneTapLoaded.bind(null, resolve), googleOneTapLoadSampleRate);
  });

  /**
   * loadGoogleOneTap: if the Google One Tap instance is not loading, then load it.
   * If it is loading, wait for it to load.
   * If it is loaded, resolve the promise
   * @param googleOneTapSetUpInformation - contains information needed to initialize Google One Tap,
   * this object contains callbacks for events like library loaded, library errored, on authentication,
   * and a value representing the client ID of NewHomeSource in Google Project Console platform.
   * @returns A promise.
   */
  function loadGoogleOneTap(googleOneTapSetUpInformation) {
    googleOneTapInstance.clientId = googleOneTapSetUpInformation.clientId;
    googleOneTapInstance.onAuthenticated = googleOneTapSetUpInformation.onAuthenticated;

    if (!googleOneTapInstance.isGoogleOneTapLoading) {
      // Set the Google One Tap instance as loading to prevent multiple requests.
      googleOneTapInstance.isGoogleOneTapLoading = true;
      return new Promise(appendGoogleOneTapScript)
        .then(googleOneTapSetUpInformation.onLibraryLoaded)
        .catch(googleOneTapSetUpInformation.onLibraryErrored);
    }

    if (googleOneTapInstance.isGoogleOneTapLoaded) {
      // If the Google One Tap instance is already loaded resolve the promise.
      return Promise.resolve()
        .then(googleOneTapSetUpInformation.onLibraryLoaded)
        .catch(googleOneTapSetUpInformation.onLibraryErrored);
    }

    // Otherwise wait to review if the Google One Tap instance has loaded.
    return waitForGoogleOneTap()
      .then(googleOneTapSetUpInformation.onLibraryLoaded)
      .catch(googleOneTapSetUpInformation.onLibraryErrored);
  }

  /**
   * showGoogleOneTapModal: this function will prompt the google one tap login modal to the user.
   */
  function showGoogleOneTapModal() {
    window.google.accounts.id.prompt();
  }

  /**
   * showGoogleOneTapModal: this function will hide the google one tap login modal to the user.
   */
  function hideGoogleOneTapModal() {
    if (window.google && window.google.accounts) {
      window.google.accounts.id.cancel();
    }
  }

  /**
   * parseJsonWebToken: This function is designed to parse a JSON Web Token (JWT) by extracting
   * and decoding its payload.
   * JWTs consist of three parts: a header, a payload, and a signature, each separated by a period ('.').
   * This function focuses on the payload, which is base64-encoded.
   * The function decodes the base64-encoded payload and returns the corresponding JSON object.
   * @param {string} token the JWT from which the payload needs to be extracted and decoded.
   * @returns a json object containing the payload information retrieved from the JWT.
   */
  const parseJsonWebToken = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64)
      .split('')
      .map((character) => '%' + ('00' + character.charCodeAt(0).toString(16)).slice(-2))
      .join(''));

    return JSON.parse(jsonPayload);
  };

  /**
   * onAuthenticated: callback executed when Google One Tap successfully authenticated an user.
   * @param {object} googleResponse containing the information needed to authenticate the user in NewHomeSource.
   */
  const onAuthenticated = async (googleResponse) => {
    const idToken = parseJsonWebToken(googleResponse.credential);
    const parameters = {
      payload: {
        locale: idToken.locale,
        picture: idToken.picture,
        email: idToken.email,
        name: idToken.name,
        givenName: idToken.given_name,
        familyName: idToken.family_name,
        subject: idToken.sub,
        audience: idToken.aud,
        emailVerified: idToken.email_verified,
        notBeforeTimeSeconds: idToken.nbf,
        hostedDomain: idToken.hd,
        issuer: idToken.iss,
        issuedAtTimeSeconds: idToken.iat,
        expirationTimeSeconds: idToken.exp,
        jwtId: idToken.jti
      },
      keepMeLogged: true
    };

    const response = await fetch('/ajax/googleOneTapLogin', {
      method: 'POST',
      body: JSON.stringify(parameters),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.ok) {
      const data = await response.json();

      if (data && data.Success) {
        window.location.reload();
      }
    }
  };

  /**
   * initialize: this is the entrance point of the Google Authentication with One Tap flow.
   * This function gets executed after the page has finished loading to avoid any performance
   * affectation to core functionality.
   */
  const initialize = () => {
    const googleOneTapClientId = document.querySelector('#nhs_googleOneTapClientId');

    // If the element [data-sign-in] is in the DOM, the user has NOT logged In
    const isUserLoggedIn = !document.querySelector('[data-sign-in]');

    if (googleOneTapClientId && !isUserLoggedIn) {
      const googleOneTapInformation = {
        onLibraryLoaded: showGoogleOneTapModal,
        onLibraryErrored: null,
        onAuthenticated,
        clientId: googleOneTapClientId.value,
      };

      loadGoogleOneTap(googleOneTapInformation);
    }
  };

  /**
   * @constructs NewHomeSource.GoogleOneTapHandler
   */
  GoogleOneTapHandler = () => {
    if (document.readyState === 'complete') {
      initialize();
    } else {
      global.addEventListener('load', initialize);
    }
  };

  /**
   * @public
   *
   * noConflict: Returns control of NewHomeSource.GoogleOneTapHandler to the previous script.
   */
  GoogleOneTapHandler.noConflict = function noConflict() {
    global.NewHomeSource.GoogleOneTapHandler = previousGoogleOneTapHandler;
    return GoogleOneTapHandler;
  };

  GoogleOneTapHandler();

  document.addEventListener('modalOpened', () => {
    hideGoogleOneTapModal();
  });

  return GoogleOneTapHandler;
}(window));

define("googleOneTapHandler", function(){});

