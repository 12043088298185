(function utmHandler(global) {
  'use strict';

  function getParameterValue(url, parameter) {
    const searchParams = new URLSearchParams(url.search);
    return searchParams.get(parameter) || null;
  }

  function getParameterValueFromHash(url, parameter) {
    const search = url.hash.replace('#', '?');
    const searchParams = new URLSearchParams(search);
    return searchParams.get(parameter) || null;
  }

  function setParameters(url, parameters) {
    const separator = url.includes('?') ? '&' : '?';
    const query = [];
    Object.keys(parameters).forEach((key) => {
      const value = parameters[key];
      if (value !== null) {
        query.push(`${key}=${encodeURIComponent(value)}`);
      }
    });

    if (query.length) {
      return `${url}${separator}${query.join('&')}`;
    }

    return `${url}`;
  }

  /**
   * Removes the query string from a URL.
   * @param {string} url - The URL to remove the query string from.
   * @returns {string} The URL without the query string.
   */
  function removeQueryString(url) {
    if (!url) {
      return url;
    }
    const urlWithoutQueryString = url.split('?')[0];
    return urlWithoutQueryString;
  }

  function createUtmUrl() {
    const location = window.location;
    const path = '/setUtmParameters';
    const utmSource = getParameterValue(location, 'utm_source') || getParameterValueFromHash(location, 'utm_source');
    const utmMedium = getParameterValue(location, 'utm_medium') || getParameterValueFromHash(location, 'utm_medium');
    const utmCampaign = getParameterValue(location, 'utm_campaign') ||
    getParameterValueFromHash(location, 'utm_campaign');
    const utmTerm = getParameterValue(location, 'utm_term') || getParameterValueFromHash(location, 'utm_term');
    const utmContent = getParameterValue(location, 'utm_content') || getParameterValueFromHash(location, 'utm_content');
    const gclid = getParameterValue(location, 'gclid') || getParameterValueFromHash(location, 'gclid');
    const refer = getParameterValue(location, 'refer') || getParameterValueFromHash(location, 'refer');
    const httpReferer = document.referrer;
    const entranceUrl = document.location.href;

    const url = setParameters(path, {
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
      utm_term: utmTerm,
      utm_content: utmContent,
      gclid,
      httpReferer,
      entranceUrl,
      refer: removeQueryString(refer),
    });
    return url;
  }

  async function setUtmParameters() {
    const url = createUtmUrl();
    const response = await fetch(url);

    if (response.ok) {
      const data = await response.json();
      document.body.dispatchEvent(new CustomEvent('trackOnDemandEvent', {
        detail: {
          data: {
            eventType: 'GAEvent',
            nhsRefer: data.HttpReferer || '',
            gaUtmSource: data.UtmSource || '',
            gaUtmMedium: data.UtmMedium || '',
            gaUtmCampaign: data.UtmCampaign || '',
            gaUtmTerm: data.UtmTerm || '',
            gaUtmContent: data.UtmContent || '',
            nhsEntranceUrl: data.EntranceUrl || '',
          }
        }
      }));
    }
  }

  if (document.readyState === 'complete') {
    setUtmParameters();
  } else {
    global.addEventListener('load', setUtmParameters);
  }
}(window));

define("utmHandler", function(){});

