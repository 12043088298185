define('nls/en/res',{
  "accept": "Accept",
  "accountBullet1": "Save Homes to your favorites",
  "accountBullet2": "Add favorite Communities",
  "accountBullet3": "Be first to know about new <span>Listings and Promotions</span>",
  "active": "Active",
  "addMeToTheInterestList": "Add Me to the Interest List",
  "address": "Address",
  "ageRestricted": "Age-Restricted",
  "alert": "Alert",
  "all": "All",
  "allAmenities": "All amenities",
  "allAreas": "All areas",
  "allBathrooms": "All Bathrooms",
  "allBedrooms": "All Bedrooms",
  "allHomeTypes": "All home types",
  "alreadyHaveAccount": "Already have an account?",
  "amenities": "Amenities",
  "anAgentWillBeInContact": "An agent will be in contact soon!",
  "appointment": "Appointment",
  "aSiteErrorHasOccurred": "A site error has occurred.",
  "askAQuestion": "Ask a question",
  "askQuestion": "Ask Question",
  "at": "at",
  "available": "Available",
  "awesomeThisIsABigStepDreamHome": "Awesome! This is a big step toward your dream home!",
  "bath": "Bath",
  "bathroom": "Bathroom",
  "bathrooms": "Bathrooms",
  "baths": "Baths",
  "bed": "Bed",
  "bedroom": "Bedroom",
  "bedrooms": "Bedrooms",
  "bedroomsHighLow": "# Bedrooms High-Low",
  "bedroomsLowHigh": "# Bedrooms Low-High",
  "beds": "Beds",
  "brochure": "Brochure",
  "builderAToZ": "Builder A-Z",
  "builderCommunity": "Builder Community",
  "builderName": "Builder Name",
  "builders": "Builders",
  "builderType": "Builder Type",
  "builderZToA": "Builder Z-A",
  "by": "by",
  "call": "Call",
  "cancel": "Cancel",
  "city": "City",
  "cityAToZ": "City A-Z",
  "cityZToA": "City Z-A",
  "clickBelowToGoThereNow": "Click below to go there now.",
  "clickUpdateSeeResults": "Click Update to see results",
  "close": "Close",
  "collapse": "Collapse",
  "comingSoon": "Coming Soon",
  "comments": "Comments",
  "communities": "Communities",
  "community": "Community",
  "communityAToZ": "Community A-Z",
  "communityType": "Community Type",
  "communityZToA": "Community Z-A",
  "connectWithThisBuilder": "Connect with this builder to receive more information!" ,
  "connectWithTheseBuilders": "Connect with these builders to receive more information!",
  "condoTownhome": "Condo/Townhome",
  "createAccount": "Create Account",
  "createAccountAndSave": "Create Account & Save",
  "createAccountSubscribe": "Subscribe to email notifications",
  "createAnAccountToGet": "Sign In or Create your Account to:",
  "createFreeAccount": "Create a FREE account",
  "currentLocation": "Current Location",
  "custom": "Custom",
  "customBuilder": "Custom Builder",
  "customBuildersMobileTab": "Custom <br/> Builders",
  "customCommunitiesMobileTab": "Build <br/> On Your Lot",
  "customHomesMobileTab": "Custom <br/> Homes",
  "dayNotAvailableToRequest": "Same-day scheduling unavailable online. To request a same-day tour, please call the builder",
  "default": "Default",
  "delete": "Delete",
  "discoverMoreGreatCommunities": "Discover more great communities:",
  "discoverMoreNearbyCommunities": "Discover more nearby communities",
  "drivingDirections": "Driving Directions",
  "email": "Email",
  "emailAddress": "Email address",
  "emailAddressIsNotValid": "Email address is not valid",
  "emailDoNotExist": "Sorry, the email address you entered did not match any accounts on file. Please try again.",
  "enterPassword": "Enter a Password",
  "enterYourEmailAddressReset": "Enter your email address to send password reset instructions.",
  "event": "Event",
  "fee": "Fee",
  "filters": "Filters",
  "firstAndLastName": "First and last name",
  "firstName": "First Name",
  "for": "for",
  "forgotPassword": "Forgot password?",
  "forYourConvenience": "For your convenience, this",
  "from": "from",
  "fullNameValidation": "Please enter a valid name.",
  "gallery": "Gallery",
  'garage': 'Garage',
  "garages": "Garages",
  "gated": "Gated",
  "gatedCommunity": "Gated community",
  "getBrochures": "Get Brochures",
  "getFreeBrochures": "Get Free Brochures",
  "getMapAndDirections": "Get map and directions",
  "getMoreInfo": "Get more info",
  "getMoreInformation": "Get More Information",
  "getTheFacts": "Get the facts",
  "getYourFreeBrochure": "Get your free brochure",
  "goBackToSignIn": "Go back to Sign In",
  "golfCourse": "Golf Course",
  "golfHomes": "golf homes",
  "goToMyAccount": "Go to My Account",
  "goToYourAccount": "Go To Your Account",
  "green": "Green",
  "greenFeatures": "Green Features",
  "hasBeenRemovedFromOurMailing": "has been removed from our mailing lists. Thank you!",
  "hasAlsoBeenSavedToYourAccount": "has also been saved to your account.",
  "hideAll": "Hide All",
  "hideHomeInfo": "Hide Home Info",
  "home": "Home",
  "homes": "Homes",
  "homeStatus": "Home Status",
  "homeType": "Home Type",
  "hotDeal": "Hot Deal",
  "images": "Images",
  "in": "in",
  "including": "including",
  "invalidPhone": "Invalid phone format",
  "lastName": "Last Name",
  "learnAboutGrandOpening": "Learn about Grand Opening",
  "learnMore": "Learn More",
  'livingArea': 'Living area',
  "livingAreas": "Living Areas",
  "locateMe": "Locate Me",
  "locations": "Locations",
  "loginAccountKeepme": "Keep me signed in",
  "loginAccountTooltip": "If checked, we will automatically sign you in when you come back.",
  "lots": "Lots",
  "luxuryHomes": "Luxury Homes",
  "manufactured": "Manufactured",
  "manufacturedHomes": "Manufactured Homes",
  "masterBedDownstairs": "Master Bed Downstairs",
  "masterBedroom": "Master Bedroom",
  "masterBedUpstairs": "Master Bed Upstairs",
  "matchingHomes": "# of Matching Homes",
  "max": "Max",
  "mGS_HOMESEARCH_DEFAULT_ERROR_MSN": "Please enter a valid location (city, state, zip code) or community name.",
  "miles": "Miles from center",
  "min": "Min",
  "model": "Model",
  "modular": "Modular",
  "more": "More",
  "moreInfo": "More info",
  "moreInformation": "More Information",
  "mSG_ACCOUNT_NO_EMAIL": "Enter a valid email address.",
  "mSG_AND": "and",
  "mSG_CREATE_ACCOUNT_INVALID_PASSWORD": "Enter a password of 7-15 characters,must contain letters and digits, and without white spaces.",
  "mSG_HOME": "home",
  "mSG_LOGIN_PASSWORD_SENT_TITLE": "Password reset instructions were sent",
  "mSG_PasswordsDontMatch": "Passwords don't match",
  "mSG_REGISTER_INVALID_ZIP": "Enter a valid zip code",
  "name": "Name",
  "natureAreas": "Nature Areas",
  "newHome": "New Home",
  "newHomes": "New Homes",
  "nhsMobileApp": "NHS Mobile APP",
  "noMax": "No Max",
  "noMin": "No Min",
  "noPrice": "No Price",
  "noResultsFound": "No Results Found",
  "not": "Not",
  "of": "of",
  "optional": "optional",
  "options": "Options",
  "or": "or",
  "outsiteUS": "Outside U.S.",
  "overview": "Overview",
  "page": "Page",
  "parks": "Parks",
  "password": "Password",
  "passwordRequired": "Password required",
  "phoneNumber": "Phone number",
  "photo": "Photo",
  "photos": "Photos",
  "place": "Place",
  "places": "Places",
  "plan": "Plan",
  "planNameAToZ": "Plan Name A-Z",
  "planNameZToA": "Plan Name Z-A",
  "plans": "Plans",
  "pleaseEnterCityStateOrZip": "Please enter a city/state or zip.",
  "pleaseEnterFirstAndLastName": "Please enter first and last name",
  "pleaseTryAgainLater": "Please try again later",
  "pool": "Pool",
  "portfolio": "Portfolio",
  "prev": "Prev",
  "price": "Price",
  "priceHighLow": "Price High-Low",
  "priceLowHigh": "Price Low-High",
  "print": "Print",
  "privacyPolicy": "Privacy policy",
  "provider": "Provider",
  "quality": "Quality",
  "questions": "Questions",
  "questionToTheBuilder": "We've sent your question to the builder.",
  "quickMoveIn": "Quick Move-In",
  "readLess": "Read Less",
  "readMore": "Read More",
  "recommendationsForYou": "Recommendations for You",
  "register": "Register",
  "required": "required",
  "resetYourPassword": "Reset Your Password",
  "responded": "responded",
  "responsiveness": "Responsiveness",
  "review": "Review",
  "reviews": "Reviews",
  "reviewTitle": "Review title",
  "save": "Save",
  "saved": "Saved",
  "savedAsFavorite": "Saved as Favorite",
  "saveToFavorites": "Save to favorites",
  "saveThisProperty": "Save This Property",
  "see": "See",
  "selectAdditionalListingsForMoreInformation": "Select additional listings for more information",
  "seeMoreCommunities": "See More Communities",
  "seeMoreHomes": "See more Homes",
  "sendMessageToBuilder": "Send Message to Builder",
  "sendMeTheDetails": "Send Me the Details",
  "sendResetEmail": "Send Password Reset Email",
  "shareThis": "Share this {0}",
  "showAll": "Show All",
  "showLess": "Show Less",
  "showMore": "Show More",
  "showMoreHomes": "Show More Homes",
  "signIn": "Sign In",
  "signInWithFacebook": "Sign In with Facebook",
  "singleFamily": "Single Family",
  "sitTightTheBuilderWillReach": "Sit tight! The builder will be reaching out to you to schedule your appointment.",
  "skip": "Skip",
  "sold": "Sold",
  "soldIn": "Sold in",
  "sortByResult": "Sorted by",
  "specialOffers": "Special Offers",
  "sportFacilities": "Sport Facilities",
  "star": "Star",
  'story': 'Story',
  "stories": "Stories",
  "storiesFloors": "Stories/Floors",
  "submit": "Submit",
  "termsOfUse": "Terms of use",
  "text": "Text",
  "thankYou": "Thank You!",
  "to": "to",
  "toThe": "to the",
  "tours": "Tours",
  "townhomes": "Townhomes",
  "trustworthiness": "Trustworthiness",
  "update": "Update",
  "userAlreadyExist": "User already exists",
  "value": "Value",
  "video": "Video",
  "videos": "Videos",
  "view": "View",
  "viewHomeInfo": "View Home Info",
  "views": "Views",
  "waterfront": "Waterfront",
  "weHaveAlsoSavedThisProperty": "We've also saved this property to your account",
  "weHaveAlsoSavedThisPropertyAndBrochure": "We've also saved this property and brochure to your account",
  "your": "Your",
  "yourBrochureIsOnTheWay": "Your brochure is on the way",
  "yourBrochuresAreOnTheirWay": "Your brochures are on their way.",
  "yourPasswordBetween5and20": "Your password must be between 5 and 20 characters",
  "yourPasswordHasBeenSent": "Your password has been sent to your email address.",
  "yourPasswordInstructions": "Passwords must be at least 7 characters, contain both alphabetic and numeric characters and must not contain white spaces.",
  "yourPasswordIsNotCorrect": "Sorry, the password you entered is not correct. Please check your spelling and try again.",
  "zipCode": "Zip code"
});
