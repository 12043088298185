define('jqueryExtend',["jquery"], function ($) {


    function createCookie(name, value, days) {
        var expires;

        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        }
        else {
            expires = "";
        }

        document.cookie = name + "=" + value + expires + "; path=/";
    };

    function scrollableElement (els) {
        for (var j = 0; j < els.length; j++) {
            var el = els[j];
            var $scrollElement = $(el);
            if ($scrollElement.scrollTop() > 0) {
                return el;
            } else {
                $scrollElement.scrollTop(1);
                var isScrollable = $scrollElement.scrollTop() > 0;
                $scrollElement.scrollTop(0);
                if (isScrollable) {
                    return el;
                }
            }
        }
        return [];
    };

    $.extend({
        adjustHeader: function () {
            var elements = jQuery(".nhs_ResContext, .nhs_CommContext, .nhs_HomesContext, .nhs_CmsContext, .nhs_HomeAccountCreated");
            var toAdjust = jQuery(".nhs_ResList, .nhs_ResMap, .nhs_CommDetail, .nhs_HomesDetail, .nhs_CmsMain, .nhs_HomeMain");

            if (elements.length && toAdjust.length) {
                var headerBottom = jQuery(".nhs_Header").outerHeight() + elements.outerHeight() - 2;
                toAdjust[0].style.marginTop = headerBottom;
            }
        },


        createCookie: function (name, value, days) {
            createCookie(name, value, days);
        },

        eraseCookie: function (name) {
            createCookie(name, "", -1);
        },

        removeParam : function(parameter)
        {
            var url=document.location.href;
            var urlparts= url.split('?');

            if (urlparts.length>=2)
            {
                var urlBase=urlparts.shift(); 
                var queryString=urlparts.join("?"); 

                var prefix = encodeURIComponent(parameter)+'=';
                var pars = queryString.split(/[&;]/g);
                for (var i= pars.length; i-->0;)               
                    if (pars[i].lastIndexOf(prefix, 0)!==-1)   
                        pars.splice(i, 1);
                url = urlBase+'?'+pars.join('&');
                window.history.pushState('',document.title,url); // added this line to push the new url directly to url bar .

            }
            return url;
        },

        getParamValuesByName: function (querystring) {
            var qstring = window.location.href.slice(window.location.href.indexOf("?") + 1).split("&");

            for (var i = 0; i < qstring.length; i++) {
                var urlparam = qstring[i].split("=");

                if (urlparam[0] === querystring) {
                    return urlparam[1];
                }
            }

            return "";
        },

        googlepush: function (events, action, label, value) {
            $.createCookie("CTAName", action);

            if (typeof dataLayer !== "undefined") {
                dataLayer.push({ 'event': "GAEvent", 'eventCategory': events, 'eventAction': action, 'eventLabel': label, 'eventValue': undefined });
            }
        },

        lazyLoadGeneral: function (visElement, urlParam, destElement) {
            var lazyLoadReady = false;
            var dataLoad = {
                ElementVisibleId: visElement,
                UpdateAction: function (isVisible) {
                    isVisible && !1 === lazyLoadReady && (lazyLoadReady = !0,
                    jQuery.ajax({
                        url: urlParam,
                        type: "GET",
                        success: function (data) { jQuery(destElement).html(data); }
                    }));
                }
            };

            jQuery.LazyLoadSection(dataLoad);
        },

        readCookie: function (name) {
            var nameEq = name + "=";
            var ca = document.cookie.split(";");

            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];

                while (c.charAt(0) === " ") {
                    c = c.substring(1, c.length);
                }

                if (c.indexOf(nameEq) === 0) {
                    return c.substring(nameEq.length, c.length);
                }
            }

            return null;
        },

        ScreenSize: function (maxX, maxY) {
            var w = window,
                d = document,
                e = d.documentElement,
                g = d.getElementsByTagName("body")[0],
                x = w.innerWidth || e.clientWidth || g.clientWidth,
                y = w.innerHeight || e.clientHeight || g.clientHeight;

            if (typeof maxY != "undefined") {
                if (y > maxY)
                    y = maxY;
            }

            if (typeof maxX != "undefined") {
                if (x > maxX)
                    x = maxX;
            }
            return { x: x, y: y };
        },

        ScrollTo: function (targetOffset, fast, callBak) {
            var scrollElem = scrollableElement(["html", "body"]);

            if (typeof targetOffset === "undefined" || targetOffset === null) {
                targetOffset = this.offset().top;
            }

            if (fast) {
                $(scrollElem).scrollTop(targetOffset);

                if ($.isFunction(callBak))
                    callBak();
            } else {
                $(scrollElem).animate({ scrollTop: targetOffset }, "slow", callBak);
            }
        },
        SetDataLayerPair: function (key, optValue) {
            try {
                var finalUrl = "/GoogleAnalytics/SetDataLayerPair";

                if (typeof (optValue) == "undefined") {
                    optValue = 0;
                }

                jQuery.ajax({
                    type: "GET",
                    url: finalUrl,
                    data: { key: key, optValue: optValue },
                    success: function (data) {
                        if (data !== "" && typeof data === "string") {
                            if (typeof (dataLayer) == "undefined") {
                                var tmpData = data;
                                var finalData = tmpData.replace("dataLayer", "window.parent.dataLayer");
                                eval(finalData);
                            } else {
                                eval(data);
                            }
                        }
                    },
                    error: function () { }
                });
            }
            catch (ex) { }
        },

        toTitleCase: function (str) {
            return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
        },

        ToString: function (obj) {
            var b = [];

            for (var n in obj) {
                if (obj.hasOwnProperty(n)) {
                    var a = obj[n];
                    "object" !== typeof a && !1 !== a && 0 !== a && "" !== a && b.push(n + "=" + a);
                }
            }

            return b.join("&");
        }
    });
});

