define(
  'loadAuth0',[
  ],
  () => {
    'use strict';

    function load(options) {
      require(
        ['handlerAuth0'],
        (handlerAuth0) => {
          handlerAuth0.init(options);
        }
      );
    }

    return {
      load
    };
  }
);

