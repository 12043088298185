define('search',["routes"], function (routes) {
  var input = document.querySelector('[data-component="search-autocomplete"]');

  function attachSearchEvents() {
    require(["SearchFormComponent", "loadCss"], function (SearchFormComponent, loadCss) {
      let form = document.getElementById("nhs_megaMenuSearchForm");

      var search = new SearchFormComponent({
        form: form,
        shouldAlwaysSubmitForm: true,
        submitButton: document.querySelector("[data-search-submit-megamenu]"),
        useAutocompleteForSubmit: true,
        validationError: document.querySelector("#nhs_field_validation_error"),
        searchTextField: form.querySelector("[data-component-rol='target-input']"),
      });
      loadCss(document.querySelector("#typeAheadCss").value);
    });

    input.removeEventListener("mouseover", attachSearchEvents);

  }

  input.addEventListener("mouseover", attachSearchEvents);
});
