// Set all the click events to show the modal
(function auth0DomEventHandler(global) {
  'use strict';

  // This method will display the modal, common method all the pages
  function signIn(options) {
    if (typeof (require) !== 'undefined') {
      require(['loadAuth0'], (loadAuth0) => {
        loadAuth0.load(options);
      });
    } else {
      const script = document.querySelector('#Auth0LockWebLogInJs');
      global.NHS.loadJs(script.value, 'nhs_Auth0LockWebLogInJs', () => {
        global.auth0LockWebLogIn.Show(global.AjaxHelper, global.extendHelper, options);
      });
    }
  }

  if (!global.auth0Params) {
    return;
  }

  // Set Listener for the click event data sign in
  document.querySelectorAll('[data-sign-in]').forEach((element) => {
    element.addEventListener('click', () => {
      signIn({
        eventsProperties: {
          click_target: 'Global Header - Sign In'
        }
      });
    });

    element.addEventListener('keyup', (event) => {
      if (event.keyCode === 13) {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        signIn({});
      }
    });
  });

  // Set Listener for the click event data sign up
  document.querySelectorAll('[data-sign-up]').forEach((element) => {
    element.addEventListener('click', () => {
      signIn({ initialScreen: 'signUp' });
    });

    element.addEventListener('keyup', (event) => {
      if (event.keyCode === 13) {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        signIn({ initialScreen: 'signUp' });
      }
    });
  });

  // The modal will automatic show if the nextpage is present
  if (global.auth0Params.nextPage) {
    signIn({ container: 'Auth0Login' });
  }

  global.auth0Dom = {
    signIn(options) {
      signIn(options);
    }
  };
}(window));

define("auth0DomEventHandler", function(){});

