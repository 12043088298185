/**
 * This module is to track pixel images.
 * This pixel images aren't visible in the DOM
 * For this reason we can not use intersection observer to lazy load.
 * To learn more read https://en.ryte.com/wiki/Tracking_Pixel
 */
(function pixelTracker(global) {
  'use strict';

  const pixelsImages = document.querySelectorAll('[data-pixel-image]');

  /**
   * @private
   *
   * loadPixels: load pixels need to track on NHS.
   */
  const loadPixels = () => {
    pixelsImages.forEach((element) => {
      const image = element;
      image.src = image.dataset.src;
    });
  };

  if (global.document.readyState === 'complete') {
    loadPixels();
  } else {
    global.addEventListener('load', loadPixels);
  }
}(window));

define("pixelTracker", function(){});

